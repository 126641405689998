import axios from "axios";
import { store } from "../redux/store";
import { setLogout } from "../redux/features/auth/authSlice";

const apiService = {};

axios.interceptors.request.use(
  (config) => {
    document.body.classList.add("loading-indicator");
    const token = store.getState().auth.accessToken;

    if (token === null) return config;
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    // Handle other errors here
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");

    return response;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    if (error.response && error.response.status === 401) {
      store.dispatch(setLogout());
      window.location.href = "/";
      return Promise.reject("Unauthorized");
    }
    return Promise.reject(error);
  }
);

apiService.get = (url) => axios.get(process.env.REACT_APP_API_URL + url);
apiService.post = (url, data) =>
  axios.post(process.env.REACT_APP_API_URL + url, data);
apiService.put = (url, data) =>
  axios.put(process.env.REACT_APP_API_URL + url, data);
apiService.delete = (url, data) =>
  axios.delete(process.env.REACT_APP_API_URL + url, data);

export default apiService;
